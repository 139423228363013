import * as actionTypes from "./actionTypes";
import Prismic from "prismic-javascript";

const endpoint = process.env.REACT_APP_PRISMIC_ENDPOINT;
const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
const Client = Prismic.client(endpoint, { accessToken });

//Sends out action to load all Articles
export const loadArticles = allArticles => {
  return {
    type: actionTypes.LOAD_ARTICLES,
    data: allArticles
  };
};

//Redux Thunk Async function that loadds all the articles and sends out the dispatch
export const startArticlesLoad = () => {
  return async dispatch => {
    try {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "articles"),
        { orderings: "[my.articles.uid]" }
      );
      if (response) {
        dispatch(loadArticles(response.results));
      }
    } catch {}
  };
};
