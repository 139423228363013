import * as actionTypes from "./actionTypes";

//Dispatches an action that sets the window dimensions based on the data it receives from the LandingPageDesktop
export const setWindowDimensions = (isMobile, isTablet) => {
  return {
    type: actionTypes.SET_WINDOW_DIMENSIONS,
    isMobile: isMobile,
    isTablet: isTablet
  };
};
