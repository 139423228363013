import React, { useState } from "react";
import { useSelector } from "react-redux";

import classes from "./Gallery.module.css";
import SectionHeader from "../../../../components/SectionHeader/SectionHeader";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import GalleryNavItem from "../../../../components/GalleryNavItem/GalleryNavItem";

const Gallery = props => {
  //Select all the available photos
  const loadedGalleryPhotos = useSelector(
    state => state.gallery.gallery_photos
  );

  //Select the array of strings containing the photo album names
  const loadedGalleryCategories = useSelector(
    state => state.gallery.gallery_categories
  );

  //To filter the photos that are shown on Desktop based on the tab clicked by the user
  const [filter, setFilter] = useState("Vistas de Sevilla");

  //To render mobile and desktop content conditionally
  const isMobile = useSelector(state => state.window.isMobile);

  return (
    <div className={classes.GalleryContainer} id={props.id}>
      <div className={classes.Header}>
        <SectionHeader
          title="Gallery"
          description="See the world through my eyes"
        />
      </div>
      <div className={classes.BodyContent}>
        {!isMobile ? (
          <div className={classes.PhotoNavigation}>
            <div className={classes.NavBar}>
              {loadedGalleryCategories
                ? loadedGalleryCategories.map(category => {
                    return (
                      <GalleryNavItem
                        selectedClass={
                          category === filter ? "selected" : "unselected"
                        }
                        key={category}
                        clicked={filterCategory => setFilter(filterCategory)}
                        filterName={category}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}

        <div className={classes.PhotoSection}>
          <div className={classes.Photos}>
            {loadedGalleryPhotos ? (
              loadedGalleryPhotos.map(gallery_photo => {
                return (!isMobile ? (
                  gallery_photo.album === filter
                ) : (
                  true
                )) ? (
                  <div
                    key={gallery_photo.url}
                    className={classes.PhotoContainer}
                  >
                    <img
                      src={gallery_photo.url}
                      className={classes.Photo}
                      alt={gallery_photo.alt}
                    />
                    {true ? (
                      <div className={classes.ImageDescription}>
                        {gallery_photo.alt}
                      </div>
                    ) : null}
                  </div>
                ) : null;
              })
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
