import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import classes from "../LandingPageDesktop/LandingPageDesktop.module.css";
import NavBarDesktop from "../../components/NavBarDesktop/NavBarDesktop";
import Home from "./Sections/Home/Home";
import Portfolio from "./Sections/Portfolio/Portfolio";
import Writing from "./Sections/Writing/Writing";
import Gallery from "./Sections/Gallery/Gallery";
import Connect from "./Sections/Connect/Connect";

import * as actions from "../../store/actions/index";

const MainPage = props => {
  //Setting up window dimensions to render differently for mobile+
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isMobile = windowWidth <= 800 ? true : false;
  const isTablet = windowWidth <= 1100 ? true : false;

  const dispatch = useDispatch();

  //Function to re-set window dimensions
  const updateScreenSize = () => {
    setWindowWidth(window.innerWidth);
    // setWindowHeight(window.innerHeight);
  };

  //Function to load gallery, articles, projects, and set window dimensions
  const loadContentAndSetDimensions = useCallback(() => {
    dispatch(actions.startProjectsLoad());
    dispatch(actions.startArticlesLoad());
    dispatch(actions.startGalleryPhotosLoad());
    dispatch(actions.setWindowDimensions(isMobile, isTablet));
  }, [dispatch, isMobile, isTablet]);

  //Set window dimensions
  useEffect(() => {
    loadContentAndSetDimensions();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });

  return (
    <div className={classes.LandingPageContainer}>
      {!isMobile ? <NavBarDesktop /> : null}
      <Home id="home" />
      <Portfolio id="portfolio" />
      <Writing id="writing" />
      <Gallery id="gallery" />
      <Connect />
    </div>
  );
};

export default MainPage;
