import * as actionTypes from "./actionTypes";
import Prismic from "prismic-javascript";

const endpoint = process.env.REACT_APP_PRISMIC_ENDPOINT;
const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
const Client = Prismic.client(endpoint, { accessToken });

//Sends out action to the gallery reducer
export const loadAllGalleryPhotos = (allGalleryPhotos, galleryCategories) => {
  return {
    type: actionTypes.LOAD_GALLERY_PHOTOS,
    photos: allGalleryPhotos,
    categories: galleryCategories
  };
};

//Redux-thunk async function that loads all the gallery photos and also picks out the categories
export const startGalleryPhotosLoad = () => {
  return async dispatch => {
    try {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "gallery_photos")
      );
      if (response) {
        const galleryCategories = [];
        const galleryPhotos = [];
        //slice(0).reverse() to reverse the array back to front
        response.results
          .slice(0)
          .reverse()
          .map(galleryGroup => {
            galleryCategories.push(galleryGroup.data.album[0].text);
            return galleryGroup.data.photos.map(singlePhoto => {
              return galleryPhotos.push({
                ...singlePhoto,
                album: galleryGroup.data.album[0].text
              });
            });
          });
        dispatch(loadAllGalleryPhotos(galleryPhotos, galleryCategories));
      }
    } catch {}
  };
};
