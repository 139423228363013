import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isMobile: null,
  isTablet: null
};

//Sets a bool for mobile devices and tablet-sized devices; variable data sent from LandingPageDesktop
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WINDOW_DIMENSIONS:
      return { isMobile: action.isMobile, isTablet: action.isTablet };
    default:
      return state;
  }
};

export default reducer;
