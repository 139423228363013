import React from "react";
import classes from "./GalleryNavItem.module.css";

const GalleryNavItem = props => {
  return (
    <div
      className={`${classes.NavItem} ${classes[props.selectedClass]}`}
      onClick={() => props.clicked(props.filterName)}
    >
      {props.filterName}
    </div>
  );
};

export default GalleryNavItem;
