import React from "react";
import classes from "./NavBarMobile.module.css";
import gallery from "../../assets/icons/Gallery.svg";
import portfolio from "../../assets/icons/Portfolio.svg";
import writing from "../../assets/icons/Writing.svg";

const NavBar = props => {
  return (
    <div className={classes.NavBar} style={{ ...props.style }}>
      <div className={classes.buttonBar}>
        <a href="#gallery">
          <img src={gallery} alt="gallery" />
        </a>
        <a href="#writing">
          <img src={writing} alt="writing" />
        </a>
        <a href="#portfolio">
          <img src={portfolio} alt="portfolio" />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
