import React, { useEffect } from "react";
import classes from "./ProjectOverlay.module.css";
import NavBar from "../NavBarDesktop/NavBarDesktop";
import close from "../../assets/icons/CloseButton.svg";

import Spinner from "../UI/Spinner/Spinner";

import { RichText } from "prismic-reactjs";

import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Project = props => {
  //Makes the DOM's body unscrollable so users can only scroll on the overlay
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  });

  //Extracts the ID param from the url and finds the corresponding project in the global fetched projects
  const projectId = String(useParams().id);
  const overlayData = useSelector(
    state =>
      state.project.projects?.find(
        project => project.data.short_slug === projectId
      )?.data
  );

  //To push new urls
  const history = useHistory();

  //To detect if app should render mobile setup
  const isMobile = useSelector(state => state.window.isMobile);

  return (
    <div className={classes.overlay}>
      {!isMobile ? (
        <NavBar
          style={{ position: "initial" }}
          closeOverlay={props.closeOverlay}
        />
      ) : null}
      <div className={classes.CloseButton} onClick={() => history.push("/")}>
        <img src={close} alt="Close button" />
      </div>
      {overlayData ? (
        <div className={classes.Container}>
          <div className={classes.Header}>
            <p>
              {overlayData.year[0].text} / {overlayData.project_type}
            </p>
          </div>
          <div>
            <p className={classes.Title}>{overlayData.project_title[0].text}</p>
          </div>
          <div className={classes.CoverImage}>
            <img src={overlayData.display_image.url} alt="Peerlift logo" />
          </div>
          <div className={classes.Credits}>
            <p>{overlayData.description[0].text}</p>
          </div>
          <div className={classes.Content}>
            {RichText.render(overlayData.body_content)}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Project;
