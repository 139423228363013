import React from "react";
import classes from "../AboutMeOverlay/AboutMeOverlay.module.css";
import { resumeLink } from "../../constants/content.js";

import ReactGA from "react-ga";

const AboutMeOverlay = props => {
  const resumeClickHandler = () => {
    ReactGA.event({
      category: "Resume",
      action: "Clicked resume on Mobile 'About Me' Overlay"
    });
    window.open(resumeLink);
  };

  const aboutMe = `I’m Jerry, a student entrepreneur and product manager
  from Haiti, 🇭🇹 living in NY, and studying Biology and Computer Science at Harvard.
  I'm a globetrotter with a love of languages 🌎 and an affection for jazz 🎶. I invite
  you to scroll and view some of my projects and ideas`;

  // const aboutMe2 = `I’m Jerry, a student entrepreneur and product manager with a love
  //  of languages and an affection for jazz. I invite you to scroll and viewsome of
  //  my projects and ideas`;

  return (
    <div className={classes.overlay} onClick={props.closeOverlay}>
      <div className={classes.Container} onClick={() => {}}>
        <div className={classes.AboutMe}>{aboutMe}</div>
        <div className={classes.resume} onClick={resumeClickHandler}>
          <p>Resume</p>
        </div>
      </div>
    </div>
  );
};

export default AboutMeOverlay;
