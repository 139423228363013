import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import classes from "../ArticleOverlay/ArticleOverlay.module.css";
import NavBar from "../NavBarDesktop/NavBarDesktop";
import close from "../../assets/icons/CloseButton.svg";
import HeartSVG from "../SvgComponents/Heart/Heart";

import Spinner from "../UI/Spinner/Spinner";

import { RichText } from "prismic-reactjs";

import ReactGA from "react-ga";

const Writing = props => {
  //To later change the color of the heart icon in the article overlay
  const [heartClicked, setHeartClicked] = useState(false);
  const heartClickHandler = () => {
    ReactGA.event({
      category: "Heart Article",
      action: `Clicked heart on article with UID: ${overlayData.title[0].text}`
    });
    setHeartClicked(!heartClicked);
  };

  //Extracts the ID param from the url and finds the corresponding article in the global fetched articles
  const articleId = String(useParams().id);
  const overlayData = useSelector(
    state =>
      state.writing.articles?.find(
        article => article.data.short_slug === articleId
      )?.data
  );

  //Makes the DOM's body unscrollable so users can only scroll on the overlay
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  });

  //To push new urls
  const history = useHistory();

  //To detect if app should render mobile setup
  const isMobile = useSelector(state => state.window.isMobile);

  return (
    <div className={classes.overlay}>
      {!isMobile ? <NavBar style={{ position: "initial" }} /> : null}
      <div className={classes.CloseButton} onClick={() => history.push("/")}>
        <img src={close} alt="Close button" />
      </div>
      {overlayData ? (
        <div className={classes.Container}>
          <div className={classes.Title}>{overlayData.title[0].text}</div>
          <div className={classes.Info}>
            <div className={classes.Stats}>
              Jerry Registre <br />
              {overlayData.date_and_read[0].text}
            </div>
            <div className={classes.Social}>
              <div className={classes.Heart} onClick={heartClickHandler}>
                <HeartSVG
                  styleProp={heartClicked ? "selected" : "unselected"}
                />
                <p></p>
              </div>
            </div>
          </div>
          <div className={classes.Content}>
            {RichText.render(overlayData.body_content)}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Writing;
