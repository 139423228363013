import React from "react";
import classes from "./NavBarDesktop.module.css";
import facebook from "../../assets/icons/Facebook.svg";
import instagram from "../../assets/icons/Instagram.svg";
import linkedin from "../../assets/icons/LinkedIn.svg";

import { resumeLink } from "../../constants/content.js";

import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const NavBar = props => {
  //To push new urls
  const history = useHistory();

  const resumeClickHandler = () => {
    ReactGA.event({
      category: "Resume",
      action: "Clicked resume on Desktop Nav Bar"
    });
    window.open(resumeLink);
  };

  return (
    <div className={classes.NavBar} style={{ ...props.style }}>
      <div className={classes.LeftSide}>
        <img
          onClick={() => window.open("https://www.facebook.com/jeregistre")}
          src={facebook}
          className={classes.facebook}
          alt="Facebook logo"
        />
        <img
          onClick={() => window.open("https://www.instagram.com/jay_r300")}
          src={instagram}
          className={classes.instagram}
          alt="Instagram logo"
        />
        <img
          onClick={() =>
            window.open("https://www.linkedin.com/in/jerryregistre")
          }
          src={linkedin}
          className={classes.linkedin}
          alt="LinkedIn logo"
        />
        <div className={classes.resume} onClick={resumeClickHandler}>
          Resume
        </div>
      </div>
      <div className={classes.RightSide}>
        <a href="#home">
          <div onClick={() => history.push("/")}>Home</div>
        </a>
        <a href="#portfolio">
          <div onClick={() => history.push("/")}>Portfolio</div>
        </a>
        <a href="#writing">
          <div onClick={() => history.push("/")}>Writing </div>
        </a>
        <a href="#gallery">
          <div onClick={() => history.push("/")}>Gallery </div>
        </a>
      </div>
    </div>
  );
};

export default NavBar;
