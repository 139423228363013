import React from "react";
import classes from "./Connect.module.css";
import SectionHeader from "../../../../components/SectionHeader/SectionHeader";
import prismic from "../../../../assets/icons/Prismic.svg";
import react from "../../../../assets/icons/React.svg";
import redux from "../../../../assets/icons/Redux.svg";
import css from "../../../../assets/icons/CSS.svg";
// import facebook from "../../../../assets/icons/Facebook.svg";
import youtube from "../../../../assets/icons/Youtube.svg";
import instagram from "../../../../assets/icons/Instagram.svg";
import linkedin from "../../../../assets/icons/LinkedIn.svg";

const Footer = props => {
  return (
    <div className={classes.FooterContainer}>
      <div className={classes.Header}>
        <SectionHeader
          title="Connect"
          description="Send me a message and start a conversation"
        />
      </div>
      <div className={classes.MainContent}>
        <div className={classes.Connect}>
          <div className={`${classes.IconsSection} ${classes.SocialMedia}`}>
            <div
               onClick={() => window.open("http://youtube.com/jerryregistre")}
            >
              <img src={youtube} alt="Youtube logo" />
            </div>
            <div
              onClick={() => window.open("https://www.instagram.com/jjerryregi")}
            >
              <img src={instagram} alt="Instagram logo" />
            </div>
            <div
              onClick={() =>
                window.open("https://www.linkedin.com/in/jerryregistre")
              }
            >
              <img src={linkedin} alt="LinkedIn logo" />
            </div>
          </div>

          <div className={classes.Text}>
            Some tools used to build this project
          </div>
          <div className={classes.IconsSection}>
            <div>
              <img src={react} alt="React icon" />
            </div>
            <div>
              <img src={redux} alt="Redux icon" />
            </div>
            <div>
              <img src={prismic} alt="Prismic icon" />
            </div>
            <div>
              <img src={css} alt="CSS icon" />
            </div>
          </div>
        </div>
      </div>
      <p>© Copyright Jerry Registre 2020</p>
    </div>
  );
};

export default Footer;
