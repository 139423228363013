import React from "react";
import classes from "./All.module.css";

const All = props => {
  return (
    <svg
      className={`${classes.svgStyle} ${classes[props.styleProp]}`}
      onClick={props.clicked}
      width="48"
      height="75"
      viewBox="0 0 48 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 48V31.0588H28.8V48H40.8V25.4118H48L24 0L0 25.4118H7.2V48H19.2Z"
        fill="#1F1BFF"
        fillOpacity="0.87"
      />
      <path
        d="M12.2642 70C11.9576 70 11.7376 69.8733 11.6042 69.62C11.4709 69.3667 11.4576 69.12 11.5642 68.88L17.4442 54.92C17.5909 54.56 17.8442 54.38 18.2042 54.38C18.5509 54.38 18.7976 54.56 18.9442 54.92L24.8442 68.9C24.9642 69.1533 24.9442 69.4 24.7842 69.64C24.6376 69.88 24.4242 70 24.1442 70C23.9842 70 23.8376 69.96 23.7042 69.88C23.5842 69.8 23.4842 69.68 23.4042 69.52L17.9042 56.18H18.5242L12.9842 69.52C12.9176 69.68 12.8176 69.8 12.6842 69.88C12.5642 69.96 12.4242 70 12.2642 70ZM13.5842 66.28L14.1842 64.94H22.3042L22.9042 66.28H13.5842ZM29.0467 70C28.5134 70 28.0334 69.8533 27.6067 69.56C27.1934 69.2667 26.8667 68.86 26.6267 68.34C26.4001 67.82 26.2867 67.22 26.2867 66.54V55.14C26.2867 54.9133 26.3534 54.7333 26.4867 54.6C26.6334 54.4533 26.8201 54.38 27.0467 54.38C27.2734 54.38 27.4534 54.4533 27.5867 54.6C27.7334 54.7333 27.8067 54.9133 27.8067 55.14V66.54C27.8067 67.1133 27.9201 67.58 28.1467 67.94C28.3867 68.3 28.6867 68.48 29.0467 68.48H29.5467C29.7467 68.48 29.9067 68.5533 30.0267 68.7C30.1467 68.8333 30.2067 69.0133 30.2067 69.24C30.2067 69.4667 30.1201 69.6533 29.9467 69.8C29.7867 69.9333 29.5734 70 29.3067 70H29.0467ZM34.3913 70C33.8579 70 33.3779 69.8533 32.9513 69.56C32.5379 69.2667 32.2113 68.86 31.9713 68.34C31.7446 67.82 31.6313 67.22 31.6313 66.54V55.14C31.6313 54.9133 31.6979 54.7333 31.8313 54.6C31.9779 54.4533 32.1646 54.38 32.3913 54.38C32.6179 54.38 32.7979 54.4533 32.9313 54.6C33.0779 54.7333 33.1513 54.9133 33.1513 55.14V66.54C33.1513 67.1133 33.2646 67.58 33.4913 67.94C33.7313 68.3 34.0313 68.48 34.3913 68.48H34.8913C35.0913 68.48 35.2513 68.5533 35.3713 68.7C35.4913 68.8333 35.5513 69.0133 35.5513 69.24C35.5513 69.4667 35.4646 69.6533 35.2913 69.8C35.1313 69.9333 34.9179 70 34.6513 70H34.3913Z"
        fill="black"
      />
    </svg>
  );
};

export default All;
