import React, { useRef, useEffect, useState } from "react";
import classes from "./Home.module.css";
import ellipseSmall from "../../../../assets/photos/EllipseSmall.svg";
import portraitWBack from "../../../../assets/photos/PortraitWBack.png";
import portraitSolo from "../../../../assets/photos/Portrait.png";
import { homeSubDescription } from "../../../../constants/content";

import { useSelector } from "react-redux";

import NavBarMobile from "../../../../components/NavBarMobile/NavBarMobile";
import AboutMeOverlay from "../../../../components/AboutMeOverlay/AboutMeOverlay";

const Home = props => {
  // A ref to inject the vh CSS variable into select component styles
  const viewportHeightRef = useRef();

  // Set vh as a CSS variable based on the current viewport and injects it into any component with the viewportHeightRef ref
  let vh = window.innerHeight * 0.01;
  const setViewport = () => {
    viewportHeightRef.current.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    setViewport();
  });

  // Controls the display of the resume overlay
  const [overlayShown, setOverlayShown] = useState(false);
  const overlayToggleHandler = () => {
    setOverlayShown(!overlayShown);
  };

  // Sets up variables to control conditional rendering for mobile, tablet, desktop
  const isMobile = useSelector(state => state.window.isMobile);
  const isTablet = useSelector(state => state.window.isTablet);

  return (
    <div
      ref={viewportHeightRef}
      className={classes.HomeContainer}
      id={props.id}
    >
      {!isMobile ? <div className={classes.NavBarGap}></div> : null}
      <div className={classes.background}>
        {!isMobile ? (
          <img
            src={ellipseSmall}
            className={classes.ellipse}
            alt="Small Ellipse"
          />
        ) : null}
        {!isMobile ? (
          <img
            src={portraitWBack}
            className={classes.portraitWBack}
            alt="Two blue ellipses and a portrait of Jerry."
          />
        ) : null}
        {isMobile ? (
          <div className={classes.portraitSolo}>
            <img
              src={portraitSolo}
              alt="Two blue ellipses and a portrait of Jerry."
            />
          </div>
        ) : null}
      </div>
      <div className={classes.description}>
        {true ? (
          <div className={classes.descriptionInnerContainer}>
            <p className={classes.descriptionMain}>
              Building
              {!isTablet ? <br /> : null} solutions
              {!isTablet ? <br /> : null} through
              {!isTablet ? <br /> : null}
              <span style={{ color: "blue" }}> product</span>
            </p>
            {isMobile ? (
              <p
                className={classes.resumeText}
                onClick={() => overlayToggleHandler()}
              >
                A bit about me / resume
              </p>
            ) : null}
          </div>
        ) : null}
        {!isMobile ? (
          <p className={classes.descriptionSub}>{homeSubDescription}</p>
        ) : null}
      </div>
      {overlayShown ? (
        <AboutMeOverlay closeOverlay={overlayToggleHandler} />
      ) : null}
      {isMobile ? <NavBarMobile ref={viewportHeightRef} /> : null}
    </div>
  );
};

export default Home;
