import React from "react";
import { Route, withRouter } from "react-router-dom";

import LandingPageDesktop from "./containers/LandingPageDesktop/LandingPageDesktop";
import ArticleOverlay from "./components/ArticleOverlay/ArticleOverlay";
import ProjectOverlay from "./components/ProjectOverlay/ProjectOverlay";

function App() {
  return (
    <div>
      <LandingPageDesktop />
      <Route exact path="/project/:id" component={ProjectOverlay} />
      <Route exact path="/writing/:id" component={ArticleOverlay} />
    </div>
  );
}

export default withRouter(App);
