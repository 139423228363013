import React, { useState } from "react";
import classes from "./Portfolio.module.css";
import StartupsSVG from "../../../../components/SvgComponents/Startups/Startups";
import AllSVG from "../../../../components/SvgComponents/All/All";
import ProjectsSVG from "../../../../components/SvgComponents/Projects/Projects";
import ConceptIdeasSVG from "../../../../components/SvgComponents/ConceptIdeas/ConceptIdeas";

import { useSelector } from "react-redux";

import SectionHeader from "../../../../components/SectionHeader/SectionHeader";
import ProjectCard from "../../../../components/Project/Project";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const Portfolio = props => {
  //Loads all projects from the global state into a variable
  const loadedProjects = useSelector(state => state.project.projects);

  //Controls the number of projects shown initially on mobile before clicking see more + the see more logic
  const [numberOfProjectsToRender, setNumberOfProjectsToRender] = useState(3);
  const seeMoreProjectsHandler = () => {
    setNumberOfProjectsToRender(numberOfProjectsToRender + 3);
  };

  //Controls what portfolio items are highlighted (selected) vs unhighlighted (unselected)
  const [filter, setFilter] = useState("All");
  const changeFilterHandler = filter => {
    setFilter(filter);
  };

  //To render mobile vs desktop content
  const isMobile = useSelector(state => state.window.isMobile);

  return (
    <div className={classes.PortfolioContainer} id={props.id}>
      {!isMobile ? (
        <div className={classes.FiltersContainer}>
          <div className={classes.Filters}>
            <AllSVG
              styleProp={filter === "All" ? "selected" : "unselected"}
              clicked={() => changeFilterHandler("All")}
            />
            <StartupsSVG
              styleProp={filter === "Startup" ? "selected" : "unselected"}
              clicked={() => changeFilterHandler("Startup")}
            />
            <ProjectsSVG
              styleProp={filter === "Project" ? "selected" : "unselected"}
              clicked={() => changeFilterHandler("Project")}
            />
            <ConceptIdeasSVG
              styleProp={filter === "Concept Idea" ? "selected" : "unselected"}
              clicked={() => changeFilterHandler("Concept Idea")}
            />
          </div>
        </div>
      ) : null}
      <div className={classes.ProjectsContainer}>
        <div className={classes.Header}>
          <div className={classes.DescriptionContainer}>
            <SectionHeader
              title="Portfolio"
              description="Past projects and new ideas"
            />
          </div>
          <div className={classes.HeaderWhitespace}> </div>
        </div>
        <div className={classes.Content}>
          <div className={classes.Projects}>
            {loadedProjects.length > 0 ? (
              loadedProjects.map((project, index) => {
                if (index < numberOfProjectsToRender || !isMobile) {
                  return (
                    <ProjectCard
                      styleProp={
                        filter === "All" ||
                        project.data.project_type === filter ||
                        isMobile
                          ? "appear"
                          : "disappear"
                      }
                      key={project.id}
                      short_slug={project.data.short_slug}
                      image={project.data.display_image.url}
                      type={project.data.project_type}
                      title={project.data.project_title[0].text}
                    />
                  );
                }
                return null;
              })
            ) : (
              <Spinner />
            )}
            {!isMobile && loadedProjects.length >= 6 ? (
              <div style={{ width: "30vw", height: "1px" }}></div>
            ) : null}
          </div>
        </div>
        {isMobile && numberOfProjectsToRender < loadedProjects.length ? (
          <div className={classes.readMore} onClick={seeMoreProjectsHandler}>
            See More
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Portfolio;
