import * as actionTypes from "../actions/actionTypes";

const initialState = {
  gallery_photos: null,
  gallery_categories: null
};

//Sets gallery photos and categories
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GALLERY_PHOTOS:
      return {
        gallery_photos: action.photos,
        gallery_categories: action.categories
      };
    default:
      return state;
  }
};

export default reducer;
