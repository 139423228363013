export const homeSubDescription = `I’m Jerry, a student entrepreneur and product manager
from Haiti, 🇭🇹 living in NY, and studying Biology and Computer Science at Harvard. I'm a
globetrotter with a love of languages 🌎 and an affection for jazz 🎶. I invite you to scroll and
view some of my projects and ideas`;

// export const homeSubDescription = `I’m Jerry, a student entrepreneur and product manager with a love of
//   languages and an affection for jazz. I invite you to scroll and view some of my projects and ideas`;

export const mobileSize = 800;

export const resumeLink =
  "https://drive.google.com/file/d/1wMcf4_r7zKxPmWi7aADZnEH6qy30KBRL/view?usp=sharing";
