import * as actionTypes from "../actions/actionTypes";

const initialState = {
  projects: []
};

//Sets all projects
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PROJECTS:
      return { projects: action.data };
    default:
      return state;
  }
};

export default reducer;
