import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";

import classes from "./Writing.module.css";
import SectionHeader from "../../../../components/SectionHeader/SectionHeader";

import { useHistory } from "react-router-dom";

const Writing = props => {
  //Loads all articles from global state
  const loadedArticles = useSelector(state => state.writing.articles);
  const history = useHistory();

  return (
    <div className={classes.WritingContainer} id={props.id}>
      <div className={classes.Header}>
        <SectionHeader
          title="Writing"
          description="Musings on product management & language/culture"
        />
      </div>
      <div className={classes.bodyContent}>
        {loadedArticles.length > 0 ? (
          loadedArticles.map(article => (
            <div
              key={article.id}
              onClick={() =>
                history.push(`/writing/${article.data.short_slug}`)
              }
              className={classes.article}
            >
              <p>{article.data.title[0].text}</p>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default Writing;
