import React from "react";
import classes from "./Project.module.css";

import { useHistory } from "react-router-dom";

const ProjectCard = props => {
  //To push new urls
  const history = useHistory();

  return (
    <div
      className={`${classes.Container} ${classes[props.styleProp]}`}
      onClick={() => history.push(`/project/${props.short_slug}`)}
    >
      <div className={classes.PhotoContainer}>
        <img src={props.image} className={classes.Photo} alt="Peerlift" />
      </div>
      <div className={classes.ProjectType}>{props.type}</div>
      <div className={classes.Description}>{props.title}</div>
    </div>
  );
};

export default ProjectCard;
