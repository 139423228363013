import * as actionTypes from "../actions/actionTypes";

const initialState = {
  articles: []
};

//Loads all articles into global state
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ARTICLES:
      return { articles: action.data };
    default:
      return state;
  }
};

export default reducer;
