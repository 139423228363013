import * as actionTypes from "./actionTypes";
import Prismic from "prismic-javascript";

const endpoint = process.env.REACT_APP_PRISMIC_ENDPOINT;
const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
const Client = Prismic.client(endpoint, { accessToken });

//Sends out action to the project reducer
export const loadProjects = allProjects => {
  return {
    type: actionTypes.LOAD_PROJECTS,
    data: allProjects
  };
};

//Redux-thunk async function that loads all the projects and also picks out the categories
export const startProjectsLoad = (orderData, token, userId) => {
  return async dispatch => {
    try {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "projects"),
        { orderings: "[my.projects.uid]" }
      );
      if (response) {
        dispatch(loadProjects(response.results));
      }
    } catch {}
  };
};
