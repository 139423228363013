import React from "react";
import classes from "./SectionHeader.module.css";

const SectionHeader = props => {
  return (
    <div className={classes.Container}>
      <p className={classes.title}>{props.title}</p>
      <p className={classes.subtitle}>{props.description}</p>
    </div>
  );
};

export default SectionHeader;
